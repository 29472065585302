import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import { useMemo } from 'react'
import { Box, HStack, Stack, VStack } from '@chakra-ui/react'
import { GrandText, ImageBox } from 'ui'
import { commaSeparator } from 'shared-utils'
import { overrideText } from 'ui/lib/overrides'
import { Currency } from 'ecosystem'
import { type CurrencyOverrides } from '../../../types'
import type { CartItemDetailProps } from './types'
import { cartItemInfoStylesOfSizes } from './constants'

type CartItemInfoProps = Pick<CartItemDetailProps, 'size'> & {
  name: string
  imgUrl?: string
  price: number
  qty: number
}
export const CartItemInfo = ({ name, imgUrl, qty, price, size = 'sm' }: CartItemInfoProps) => {
  const overrides = useOverridesContext<keyof CurrencyOverrides>()
  const { priceMaximumFractionDigits } = useUIGlobalsContext()

  const sizes = useMemo(() => cartItemInfoStylesOfSizes[size], [size])

  return (
    <VStack className="CartItemInfo" gap={4} w="100%">
      <Stack direction={{ base: 'column', sm: 'row' }} w="100%">
        <GrandText
          flex={1}
          fontSize={{
            base: 'xs',
            md: 'sm'
          }}
          isTruncated
          w={{
            base: '100%',
            sm: 'auto'
          }}>
          {name}
        </GrandText>

        <HStack spacing={4} w="full">
          {!!imgUrl && (
            <ImageBox
              alignSelf="flex-start"
              imageProps={{
                alt: name,
                title: name,
                src: imgUrl,
                style: {
                  objectFit: 'contain'
                },
                sizes: '7em'
              }}
              {...sizes.image}
            />
          )}

          <VStack flex={1} w="full">
            <HStack
              justifyContent={{
                base: 'flex-end',
                sm: 'initial'
              }}
              w="100%">
              <GrandText
                display={{ base: 'none', sm: 'initial' }}
                flexGrow={1}
                w="auto"
                {...sizes.itemTitle}>
                {name}
              </GrandText>

              <HStack align="center" flexShrink={0}>
                <GrandText flexGrow={1} fontWeight="medium" textAlign="center" {...sizes.itemPrice}>
                  {price ? commaSeparator(price, priceMaximumFractionDigits) : 0}{' '}
                  {overrideText(Currency.SEK, overrides?.currency)}
                </GrandText>

                <Box whiteSpace="nowrap" {...sizes.itemQuantity}>
                  x {qty}
                </Box>
              </HStack>
            </HStack>
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  )
}

import { getProductImages } from 'api'
import { useMemo } from 'react'
import { VStack } from '@chakra-ui/react'
import { type ICartItemGroup } from 'ecosystem'
import type { CartItemDetailProps } from './types'
import { CartItemInfo } from './CartItemInfo'

export const CartItemDetailGroup = ({
  cartItem,
  size = 'sm'
}: Omit<CartItemDetailProps, 'cartItem'> & { cartItem: ICartItemGroup }) => {
  const { items, name, imgUrl } = cartItem.meta.groupType

  const totalPrice = useMemo(() => {
    return items.reduce((acc, item) => {
      return acc + item.product.price * (item.originalQty ?? 1)
    }, 0)
  }, [items])

  return (
    <VStack alignItems="stretch" w="100%">
      <CartItemInfo
        name={name ?? ''}
        qty={cartItem.qty}
        imgUrl={imgUrl}
        price={totalPrice}
        size={size}
      />
      <VStack alignItems="stretch" w="100%" pl={2}>
        {items.map((item) => (
          <CartItemInfo
            key={item.product.id}
            name={item.product.name ?? ''}
            qty={item.qty ?? 1}
            imgUrl={getProductImages(item.product.medias).mainImageUrl}
            price={item.product.price}
            size="xs"
          />
        ))}
      </VStack>
    </VStack>
  )
}

import type { FC } from 'react'
import type { CartItemDetailProps } from './types'
import { CartItemDetailSingle } from './CartItemDetailSingle'
import { CartItemDetailGroup } from './CartItemDetailGroup'

export const CartItemDetail: FC<CartItemDetailProps> = ({ cartItem, ...props }) => {
  switch (cartItem.type) {
    case 'SINGLE':
      return <CartItemDetailSingle cartItem={cartItem} {...props} />
    case 'GROUP':
      return <CartItemDetailGroup cartItem={cartItem} {...props} />
  }
}

import { FC, ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { ModalConfirmationOverrides } from 'storefront-modules/types'

export interface IModalConfirmationProps {
  component: ReactElement | string
}

const ModalConfirmation: FC<IModalConfirmationProps> = () => {
  const overrides = useOverridesContext<keyof ModalConfirmationOverrides>()

  return (
    <Box h="100%" w="100%">
      <Box pb={10} px={5} textAlign="center" w="100%">
        {overrideText(
          'Tack för att du skickade in din förfrågan! Vi kommer att kontakta dig så snart som möjligt.',
          overrides?.contactConfirmationMessage
        )}
      </Box>
    </Box>
  )
}

export default ModalConfirmation

import { FC } from 'react'
import { Avatar, CloseButton, HStack, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandText } from 'ui'
import { useSlideOut } from '#/src/custom/controllers'
import { Currency, OrderDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { commaSeparator } from 'shared-utils'
import { CustomerOrderItem, OrderGroupItem } from 'storefront-modules/account'
import { CurrencyOverrides, CustomerOrderDetailOverrides } from 'storefront-modules/types'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import appConfig from '#/src/appConfig'

type SlideOutCustomerOrderDetailOverrides = CurrencyOverrides & CustomerOrderDetailOverrides

export interface SlideOutCustomerOrderDetailProps {
  order: OrderDTO
}

const SlideOutCustomerOrderDetail: FC<SlideOutCustomerOrderDetailProps> = ({ order }) => {
  const overrides = useOverridesContext<keyof SlideOutCustomerOrderDetailOverrides>()
  const { closeSlideOut } = useSlideOut()
  return (
    <VStack spacing={2} w="full">
      <CloseButton alignSelf="flex-end" onClick={closeSlideOut} />
      <GrandHeading
        textAlign="right"
        title={overrideText('Information', overrides?.customerOrderDetailInformation)}
      />
      <CustomerOrderItem customerOrder={order} />

      <GrandHeading
        textAlign="right"
        title={overrideText('Leveransadress', overrides?.customerOrderDetailDeliveryAddress)}
      />
      <OrderGroupItem
        label={`${overrideText('Adress', overrides?.customerOrderDetailAddress)} 1`}
        value={order.shippingAddress?.address1}
      />
      <OrderGroupItem
        label={`${overrideText('Adress', overrides?.customerOrderDetailAddress)} 2`}
        value={order.shippingAddress?.address2}
      />
      <OrderGroupItem
        label={overrideText('Postnummer', overrides?.customerOrderDetailPostalCode)}
        value={order.shippingAddress?.postalCode}
      />
      <OrderGroupItem
        label={overrideText('Uppmärksamhet', overrides?.customerOrderDetailAttention)}
        value={order.shippingAddress?.attention}
      />
      <OrderGroupItem
        label={overrideText('Mobil', overrides?.customerOrderDetailMobile)}
        value={order.shippingAddress?.mobilePhone}
      />

      <GrandHeading
        textAlign="right"
        title={overrideText('Din beställning', overrides?.customerOrderDetailYourOrder)}
      />
      <VStack w="full">
        {order.items?.map((item, index) => {
          const { mainImageUrl } = getProductImages(item.product.medias)
          return (
            <VStack key={index} w="full">
              <HStack w="full">
                <Avatar flexGrow={0} src={mainImageUrl} />
                <GrandText fontWeight="bold">{item.product.name}</GrandText>
                <GrandText>
                  {item.quantity} {overrideText('st.', overrides?.customerOrderDetailPcs)}
                </GrandText>
                <GrandText>
                  {commaSeparator(item.unitPrice)}{' '}
                  {overrideText(appConfig.currency ?? Currency.SEK, overrides?.currency)}
                </GrandText>
              </HStack>
            </VStack>
          )
        })}
      </VStack>
    </VStack>
  )
}

export default SlideOutCustomerOrderDetail

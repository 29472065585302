import appConfig from '#/src/appConfig'
import { Aihub } from 'storefront-modules/aihub'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { useSelector } from 'shared-redux'
import { selectIsWindowOnTop } from 'shared-redux/state'
import { DeliveryDTO, Option } from 'ecosystem'
import { Box } from '@chakra-ui/react'
import { ProductListItemBuy } from 'storefront-modules/products'

type CustomAiHubProps = {
  deliveries?: Option<DeliveryDTO[]>
}

export const CustomAiHub = ({ deliveries }: CustomAiHubProps) => {
  const { handleAddToCart, cartItems, cartDiscountCode, cartLocale, cartStateUuid, cartTotal } =
    useCustomCart()
  const { handleProductRequest } = useProductRequest()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  return (
    <Aihub
      cartState={{
        cartItems,
        cartDiscountCode,
        cartLocale,
        cartStateUuid,
        cartTotal
      }}
      handleAddToCart={handleAddToCart}
      wrapperProps={{
        top: isWindowOnTop ? appConfig.topNavHeight.spacer : { base: '8vh', md: '14vh' }
      }}
      assistant={{
        isEnabled: process.env.NEXT_PUBLIC_AI_ASSISTANT_ENABLED === 'true',
        paths: {
          chat: '/api/assistant/v2/chat',
          thread: '/api/assistant/v2/thread',
          textToSpeech: '/api/assistant/v2/text-to-speech',
          settings: '/api/assistant/settings',
          products: appConfig.paths.productsUrlPath,
          brands: appConfig.paths.brandsUrlPath,
          tags: appConfig.paths.tagsUrlPath,
          categories: appConfig.paths.categoriesUrlPath
        },
        components: {
          price: {
            render: ({ product }) => (
              <>
                {!!product.originalProduct && (
                  <Box
                    sx={{
                      '.product-list-item-buy': {
                        '&__button-wrapper': {
                          position: 'static'
                        }
                      }
                    }}
                    w="100%">
                    <ProductListItemBuy
                      priceRequestButtonProps={{
                        onClick: (e) => e.stopPropagation()
                      }}
                      buttonProps={{
                        onClick: (e) => e.stopPropagation()
                      }}
                      price={{
                        price: product.originalProduct.price,
                        originalPrice: product.originalProduct.originalPrice
                      }}
                      product={product.originalProduct}
                      deliveryOptions={deliveries ?? null}
                      productsUrlPath={appConfig.paths.productsUrlPath}
                      onBuy={(product) => {
                        handleAddToCart({
                          id: product.id,
                          type: 'SINGLE',
                          qty: 1,
                          meta: {
                            singleType: {
                              product
                            }
                          }
                        })
                      }}
                      onProductRequest={handleProductRequest}
                    />
                  </Box>
                )}
              </>
            )
          }
        }
      }}
      offers={{
        isEnabled: true,
        paths: {
          products: appConfig.paths.productsUrlPath
        }
      }}
    />
  )
}

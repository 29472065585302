import { HStack, Text, VStack } from '@chakra-ui/react'
import { MouseEvent } from 'react'
import { GrandNextLink } from 'ui'
import { useRouter } from 'next/navigation'

import { useSelector } from 'shared-redux'
import { commaSeparator } from 'shared-utils'
import { selectedCart } from 'shared-redux/state'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'
import appConfig from '#/src/appConfig'
import { CurrencyOverrides } from 'storefront-modules/types'

type CartSlideOutFooterOverrides = CurrencyOverrides & {
  cartTotal?: string
  cartToCheckout?: string
}

function CartSlideOutFooter() {
  const overrides = useOverridesContext<keyof CartSlideOutFooterOverrides>()
  const { cartTotal, cartItems } = useSelector(selectedCart)
  const router = useRouter()

  const handleCheckout = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    router.push('/checkout')
  }

  return (
    <VStack
      pb={4}
      spacing={{
        base: 4,
        md: 2
      }}
      w="100%">
      <HStack fontSize="md" fontWeight="bold" justify="space-between" w="100%">
        <Text>{overrideText('Totalt', overrides?.cartTotal)}</Text>
        <Text>{`${commaSeparator(cartTotal)} ${overrideText(appConfig.currency ?? Currency.SEK, overrides?.currency)}`}</Text>
      </HStack>
      <GrandNextLink
        alignSelf="center"
        aria-label="checkoutt"
        bg="primary"
        href="/checkout"
        isButton
        isDisabled={!cartItems.length}
        onClick={handleCheckout}
        w={{
          base: '100%',
          sm: '50%'
        }}>
        {overrideText('Till varukorg', overrides?.cartToCheckout)}
      </GrandNextLink>
    </VStack>
  )
}

export default CartSlideOutFooter

'use client'
import type { FC } from 'react'
import { useState } from 'react'
import type { StackProps, ButtonProps } from '@chakra-ui/react'
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, IconButton } from '@chakra-ui/react'
import type { FormFieldType } from 'react-formatge/dist/esm/types'
import type { FormSchemaUpdatedDataState } from 'react-formatge/dist/cjs/types'
import { formValidationRgx } from 'react-formatge'
import { MdClear } from 'react-icons/md'
import type { FileRejection } from 'ui'
import { GrandDropZone } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { VERCEL_UPLOAD_MAX_SIZE } from 'shared-utils'
import { rgxExtension } from '../react-formatge-extensions/rgxExtension'
import { FormWrapperCustom } from '../components'

export interface ContactFormType {
  name: string
  phone: string
  email: string
  description: string
}

export interface ContactUsSimpleFormOverrides {
  contactUsFormName?: string
  contactUsFormNamePlaceholder?: string
  contactUsFormPhone?: string
  contactUsFormPhonePlaceholder?: string
  contactUsFormEmail?: string
  contactUsFormEmailPlaceholder?: string
  contactUsFormDescription?: string
  contactUsFormDescriptionPlaceholder?: string
  contactUsFormBtnLabel?: string
  contactUsFormAttachFiles?: string
}

export interface IContactUsSimpleFormProps<ContactFormData = ContactFormType> extends StackProps {
  onFormSubmit: (formData: Partial<ContactFormData>, attachments: File[]) => Promise<void>
  error?: string
  customDescriptionPlaceholder?: string
  withDropZone?: boolean
  submitButtonProps?: ButtonProps
}

const ContactUsSimpleForm: FC<IContactUsSimpleFormProps> = ({
  error,
  onFormSubmit,
  customDescriptionPlaceholder,
  withDropZone,
  submitButtonProps,
  ...props
}) => {
  const [accepted, setAccepted] = useState<File[]>([])
  const [rejected, setRejected] = useState<FileRejection[]>([])
  const overrides = useOverridesContext<keyof ContactUsSimpleFormOverrides>()

  const clearFileUpload = () => {
    setAccepted([])
    setRejected([])
  }
  const handleOnFormSubmit = async (
    updatedData: Partial<ContactFormType>,
    formData: FormSchemaUpdatedDataState<ContactFormType>
  ) => {
    // creates a new object containing the start and end dates
    await onFormSubmit(updatedData, accepted)
      .then(() => {
        // resets fields after submit
        formData.name.value = ''
        formData.phone.value = ''
        formData.email.value = ''
        formData.description.value = ''
        clearFileUpload()
      })
      .catch(() => {
        /* empty */
      })
  }

  const onDrop = (acceptedFiles: File[]) => {
    setAccepted(acceptedFiles)
  }

  const onDropRejected = (acceptedFiles: FileRejection[]) => {
    setRejected(acceptedFiles)
  }

  const inputFields: FormFieldType<ContactFormType>[] = [
    {
      name: 'name',
      componentType: 'input',
      label: overrideText('För-och efternamn', overrides?.contactUsFormName),
      placeholder: overrideText('För-och efternamn', overrides?.contactUsFormNamePlaceholder),
      initialValue: '',
      validation: { required: true }
    },
    {
      name: 'phone',
      label: overrideText('Telefon', overrides?.contactUsFormPhone),
      componentType: 'input',
      placeholder: overrideText('Telefon', overrides?.contactUsFormPhonePlaceholder),
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: rgxExtension.phoneGeneric,
          error: 'Invalid phone number.'
        }
      }
    },
    {
      name: 'email',
      label: overrideText('E-post', overrides?.contactUsFormEmail),
      componentType: 'input',
      placeholder: overrideText('E-post', overrides?.contactUsFormEmailPlaceholder),
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email.'
        }
      }
    },
    {
      name: 'description',
      label: overrideText('Beskrivning', overrides?.contactUsFormDescription),
      componentType: 'input',
      placeholder: overrideText(
        'Beskrivning',
        customDescriptionPlaceholder || overrides?.contactUsFormDescriptionPlaceholder
      ),
      initialValue: '',
      textarea: true
    }
  ]

  const buttonProps: ButtonProps = {
    children: overrideText('Skicka', overrides?.contactUsFormBtnLabel),
    id: 'contact_form_snd-1',
    ...submitButtonProps
  }

  return (
    <FormWrapperCustom<ContactFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}>
      {withDropZone ? (
        <Box mt={2} w="100%">
          <FormControl w="100%">
            <HStack h={10} w="100%">
              <HStack flexGrow={1}>
                <FormLabel
                  htmlFor={overrideText('Infoga filer', overrides?.contactUsFormAttachFiles)}
                  m={0}>
                  {overrideText('Infoga filer', overrides?.contactUsFormAttachFiles)}
                </FormLabel>
                <FormErrorMessage fontSize="xs" justifyContent="flex-end" textAlign="right" />
              </HStack>
              {(Boolean(accepted.length) || Boolean(rejected.length)) && (
                <IconButton
                  alignItems="center"
                  aria-label="clear upload files"
                  background=""
                  icon={<MdClear />}
                  justifyContent="center"
                  onClick={clearFileUpload}
                  shadow="none"
                  size="xs"
                />
              )}
            </HStack>
            <GrandDropZone
              dropZoneStyles={{ w: '100%' }}
              options={{
                maxFiles: 5,
                maxSize: VERCEL_UPLOAD_MAX_SIZE
              }}
              w="100%"
              {...{ accepted, rejected, onDrop, onDropRejected }}
            />
          </FormControl>
        </Box>
      ) : null}
    </FormWrapperCustom>
  )
}

export default ContactUsSimpleForm

import type { FC } from 'react'
import type { CartItem } from 'ecosystem'
import { EmptyMessage } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { CartItemDetail } from './CartItemDetail'

interface ICartItemsProps {
  cartItems: CartItem[]
  size?: 'sm' | 'md' | 'lg'
}

export interface CartItemsOverrides {
  emptyCart?: string
}

const CartItems: FC<ICartItemsProps> = ({ cartItems, size }) => {
  const overrides = useOverridesContext<keyof CartItemsOverrides>()
  if (!cartItems.length) {
    return (
      <EmptyMessage
        text={overrideText(
          'Din varukorg är tom, försök lägga till några varor.',
          overrides?.emptyCart
        )}
      />
    )
  }

  return (
    <>
      {cartItems.map((item) => (
        <CartItemDetail cartItem={item} key={item.id} size={size} />
      ))}
    </>
  )
}

export default CartItems

'use client'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import granditudeLogo from '../../images/granditude_logo.png'
import { MdClose, MdOutlineMenu } from 'react-icons/md'

import { useDispatch, useSelector } from 'shared-redux'
import { usePathname } from 'next/navigation'
import {
  selectedMenuCategoryId,
  selectIsWindowOnTop,
  setSelectedMenuCategories,
  setSelectedMenuCategory,
  setSelectedMenuCategoryId
} from 'shared-redux/state'
import { CustomSlideOutPayloadProps, useModal, useSlideOut } from '#/src/custom/controllers'
import { CategoryDTO, FooterItem, RequiredPageProps, SocialMediaItem } from 'ecosystem'
import appConfig from '#/src/appConfig'
import SlideOutCart from '#/src/custom/controllers/slideouts/SlideOutCart'
import CartSlideOutFooter from '#/src/custom/controllers/slideouts/CartSlideOutFooter'
import { CartButton, MenuItem, TopNav, TopNavSpacer } from 'ui/storefront'
import { ImageBox, MAX_CONTENT_WIDTH } from 'ui'
import CustomFooter from '#/src/custom/layout/CustomFooter'
import { LinkProps } from '@chakra-ui/react'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import { PageLayoutContainer } from 'storefront-modules/page'
import { SearchComponent } from 'storefront-modules/search'
import { useCustomSearch } from '#/src/custom/search/hooks/useCustomSearch'
import { sortCategories } from 'shared-utils'
import { AihubMobileTrigger } from 'storefront-modules/aihub'
import { NavBarAction } from 'ui/storefront/layout/TopNavContent'

export type CustomLayoutProps = RequiredPageProps & {
  children: ReactNode | ReactNode[]
}

const CustomLayout: FC<CustomLayoutProps> = ({
  children,
  footerWidgets,
  staticPages,
  categories
}) => {
  const { isHidden, closeSlideOut, openSlideOut, componentId, overrideComponent } = useSlideOut()
  const menuCategoryId = useSelector(selectedMenuCategoryId)
  const dispatch = useDispatch()
  const { isHidden: isModalHidden, size: modalSize } = useModal()
  const { cartCount, isCartOpen } = useCustomCart()
  const pathname = usePathname()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  const handleCategoriesToggle = useCallback(
    (items: CategoryDTO[]) => {
      if (!items.length) {
        return closeSlideOut()
      }

      const slideOutProps = {
        title: 'Categories',
        componentId: 'categories' as const,
        drawerProps: null
      }

      if (!isHidden && componentId !== 'categories') {
        overrideComponent(slideOutProps)
      } else if (isHidden) {
        openSlideOut(slideOutProps)
      }

      dispatch(setSelectedMenuCategories(items || []))
    },
    [closeSlideOut, componentId, dispatch, isHidden, openSlideOut, overrideComponent]
  )

  const categoryItems = useMemo(() => {
    return sortCategories(categories || []).map((item) => {
      const isActive = !isHidden && menuCategoryId === item.slug
      const path = `${appConfig.paths.categoriesUrlPath}/${item.slug}`

      return {
        id: item.slug,
        text: item.name,
        path,
        isActive,
        other: item.subCategories || [],
        onHoverEnter: () => {
          if (!isActive) {
            handleCategoriesToggle(item.subCategories || [])
            dispatch(setSelectedMenuCategoryId(item.slug))
            dispatch(setSelectedMenuCategory(item.name))
          }
        }
      }
    })
  }, [categories, dispatch, handleCategoriesToggle, isHidden, menuCategoryId])

  const panelItems = appConfig.topNavPanel?.items

  const menuItems: {
    categories: MenuItem[]
    other: MenuItem[]
  } = useMemo(() => {
    return {
      categories: [...categoryItems],
      other: [
        ...(staticPages
          ? staticPages.map((sp) => ({
              id: 'other-' + sp.id,
              text: sp.name,
              path: sp.slug ? `/${sp.slug}` : '/',
              onHoverEnter: () => closeSlideOut()
            }))
          : []),
        ...appConfig.staticLinks.map(({ label, ...s }) => ({
          ...s,
          text: label,
          onHoverEnter: () => closeSlideOut()
        }))
      ]
    }
  }, [categoryItems, closeSlideOut, staticPages])

  const socialMediaItems: SocialMediaItem<LinkProps>[] = appConfig.socialMediaItems

  const footerItems: FooterItem[] = footerWidgets.map(({ widget }) => widget)

  const customSearch = useCustomSearch()

  const onSearch = useCallback(() => {
    const slideOutProps: CustomSlideOutPayloadProps<void> = {
      title: 'Search',
      componentId: 'search' as const,
      shouldUnmountComponentAfterClose: true,
      drawerProps: {
        sx: {
          bg: 'transparent',
          px: '0',
          boxShadow: 'none',
          '.slide-out__content-container': {
            py: '0',
            maxWidth: MAX_CONTENT_WIDTH,
            margin: 'auto',
            bg: 'none'
          },
          '.slide-out__content-body': {
            px: '0'
          }
        }
      }
    }

    if (!isHidden && componentId !== 'search') {
      overrideComponent(slideOutProps)
    } else if (isHidden) {
      openSlideOut(slideOutProps)
    } else {
      closeSlideOut()
    }
  }, [closeSlideOut, componentId, isHidden, openSlideOut, overrideComponent])

  const navbarActions: NavBarAction[] = [
    {
      component: <AihubMobileTrigger />,
      showsOnMobile: true
    },
    {
      component: <SearchComponent onSearch={onSearch} {...customSearch} />,
      showsOnMobile: true
    },
    ...(appConfig.showCart
      ? [
          {
            component: (
              <CartButton
                badgeCount={cartCount}
                isHidden={pathname === '/checkout'}
                isOpen={isCartOpen}
                path="/checkout">
                <SlideOutCart />
                <CartSlideOutFooter />
              </CartButton>
            ),
            showsOnMobile: true
          }
        ]
      : [])
  ].map((navBarAction, index) => ({
    ...navBarAction,
    id: index
  }))

  const logoComponent = (
    <ImageBox
      h={10}
      imageProps={{
        title: 'logo',
        alt: 'logo',
        src: appConfig.logoUrl || granditudeLogo.src,
        style: {
          objectFit: 'contain'
        },
        quality: 100,
        priority: true,
        sizes: '10em'
      }}
      w={40}
    />
  )

  const handleOnMenuToggle = (menuItems: MenuItem[]) => {
    if (isHidden) {
      openSlideOut({
        title: '',
        componentId: 'mobileMenu',
        footerId: 'mobileMenu',
        extraArgs: {
          menuItems
        },
        drawerProps: null
      })
    } else {
      closeSlideOut()
    }
  }

  const handleOnMenuHover = (isInSide: boolean) => {
    if (isInSide && componentId === 'categories') {
      closeSlideOut()
    }
  }

  return (
    <PageLayoutContainer
      pageContextProps={{
        categories: categories || [],
        footerWidgets,
        staticPages
      }}
      position="relative">
      <TopNav
        contentHeight={appConfig.topNavHeight.content}
        logo={logoComponent}
        onMenuHover={handleOnMenuHover}
        onMenuToggle={handleOnMenuToggle}
        panelHeight={isWindowOnTop ? appConfig.topNavHeight.panel : 0}
        toggleIcon={isHidden ? <MdOutlineMenu /> : <MdClose />}
        zIndex={!isModalHidden && modalSize === 'full' ? 0 : 9000}
        {...{ panelItems, menuItems, navbarActions, isHidden }}
      />
      <TopNavSpacer
        h={isWindowOnTop ? appConfig.topNavHeight.spacer : { base: '8vh', md: '14vh' }}
      />
      {children}
      <CustomFooter logo={logoComponent} {...{ footerItems, socialMediaItems }} />
    </PageLayoutContainer>
  )
}

export default CustomLayout

export const cartItemInfoStylesOfSizes = {
  xs: {
    image: {
      w: {
        base: 8,
        md: 10
      },
      h: {
        base: 8,
        md: 10
      }
    },
    itemTitle: {
      fontSize: 'xs'
    },
    itemPrice: {
      fontSize: {
        base: '2xs',
        md: 'xs'
      }
    },
    itemQuantity: {
      fontSize: '2xs'
    }
  },
  sm: {
    image: {
      w: {
        base: 10,
        md: 12
      },
      h: {
        base: 10,
        md: 12
      }
    },
    itemTitle: {
      fontSize: 'sm'
    },
    itemPrice: {
      fontSize: {
        base: 'xs',
        md: 'sm'
      }
    },
    itemQuantity: {
      fontSize: 'xs'
    }
  },
  md: {
    image: {
      w: { base: 18, md: 20 },
      h: { base: 18, md: 20 }
    },
    itemTitle: {
      fontSize: 'md'
    },
    itemPrice: {
      fontSize: 'md'
    },
    itemQuantity: {
      fontSize: 'sm'
    }
  },
  lg: {
    image: {
      w: { base: '16vw', md: 28 },
      h: { base: '16vw', md: 28 }
    },
    itemTitle: {
      fontSize: 'lg'
    },
    itemPrice: {
      fontSize: 'lg'
    },
    itemQuantity: {
      fontSize: 'md'
    }
  }
}

'use client'
import React, { FC, ReactNode } from 'react'
import { useGTMPageViewTracker } from 'storefront-modules/data-collection'
import { CustomAiHub } from '#/src/custom/components/CustomAiHub'
import { DeliveryDTO, Option } from 'ecosystem'
import { NotificationsProvider } from 'storefront-modules/notifications'
import { AihubAnalyticsProvider } from 'storefront-modules/aihub'

interface AppComponentProps {
  children: ReactNode
  deliveries?: Option<DeliveryDTO[]>
}
const AppComponent: FC<AppComponentProps> = ({ children, deliveries }) => {
  useGTMPageViewTracker()

  return (
    <NotificationsProvider isDebug>
      <AihubAnalyticsProvider skipConsent>
        {children}
        <CustomAiHub deliveries={deliveries} />
      </AihubAnalyticsProvider>
    </NotificationsProvider>
  )
}

export default AppComponent

import type { StackProps } from '@chakra-ui/react'
import type { FC } from 'react'
import type {
  FormFieldType,
  FormSchemaUpdatedDataState,
  OnFormSubmit
} from 'react-formatge/dist/esm/types'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { rgxExtension } from '../react-formatge-extensions/rgxExtension'
import { FormWrapperCustom } from '../components'

interface ChangePasswordFormType {
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

export interface ChangePasswordSubmitData {
  oldPassword?: string
  password: string
}

export interface ChangePasswordFormOverrides {
  changePwdFormMsg?: string
  changePwdFormOldPwd?: string
  changePwdFormNewPwd?: string
  changePwdFormRepeatPwd?: string
  changePwdFormBtnLabel?: string
}

interface ChangePasswordFormProps extends StackProps {
  withOldPassword?: boolean
  onFormSubmit?: OnFormSubmit<ChangePasswordSubmitData>
  error?: string
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  withOldPassword,
  error,
  onFormSubmit,
  ...props
}) => {
  const overrides = useOverridesContext<keyof ChangePasswordFormOverrides>()
  const passwordMsg = overrideText(
    'Lösenordet måste innehålla minst en siffra, en liten och en stor bokstav, ett specialtecken och vara mellan 8 och 20 tecken långt.',
    overrides?.changePwdFormMsg
  )

  const inputFields: FormFieldType<ChangePasswordFormType>[] = [
    {
      name: 'oldPassword',
      isHidden: !withOldPassword,
      componentType: 'input',
      label: overrideText('Gammalt lösenord', overrides?.changePwdFormOldPwd),
      placeholder: overrideText('Gammalt lösenord', overrides?.changePwdFormOldPwd),
      type: 'password',
      initialValue: '',
      validation: {
        required: true
      }
    },
    {
      name: 'newPassword',
      componentType: 'input',
      label: overrideText('Nytt lösenord', overrides?.changePwdFormNewPwd),
      placeholder: overrideText('Nytt lösenord', overrides?.changePwdFormNewPwd),
      type: 'password',
      initialValue: '',
      helperText: passwordMsg,
      validation: {
        required: true,
        validator: {
          regEx: rgxExtension.password,
          error: 'Wrong password format'
        }
      }
    },
    {
      name: 'repeatPassword',
      componentType: 'input',
      label: overrideText('Bekräfta lösenord', overrides?.changePwdFormRepeatPwd),
      placeholder: overrideText('Bekräfta lösenord', overrides?.changePwdFormRepeatPwd),
      type: 'password',
      initialValue: '',
      validation: {
        required: true,
        equalsField: {
          field: 'newPassword',
          error: 'No match'
        }
      }
    }
  ]

  const buttonProps = {
    children: overrideText('Spara', overrides?.changePwdFormBtnLabel)
  }

  const handleOnFormSubmit = async (
    updatedData: Partial<ChangePasswordFormType>,
    formData: FormSchemaUpdatedDataState<ChangePasswordFormType>
  ) => {
    if (!onFormSubmit) return
    await onFormSubmit({
      password: updatedData.newPassword || '',
      ...(withOldPassword ? { oldPassword: updatedData.oldPassword } : {})
    })
    formData.oldPassword.value = ''
    formData.newPassword.value = ''
    formData.repeatPassword.value = ''
  }

  return (
    <FormWrapperCustom<ChangePasswordFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}
    />
  )
}

export default ChangePasswordForm

import { getProductImages } from 'api'
import { type ICartItemSingle } from 'ecosystem'
import type { CartItemDetailProps } from './types'
import { CartItemInfo } from './CartItemInfo'

export const CartItemDetailSingle = ({
  cartItem,
  size = 'sm'
}: Omit<CartItemDetailProps, 'cartItem'> & { cartItem: ICartItemSingle }) => {
  const { product } = cartItem.meta.singleType
  const { mainImageUrl } = getProductImages(product.medias)

  return (
    <CartItemInfo
      name={product.name}
      qty={cartItem.qty}
      imgUrl={mainImageUrl}
      price={product.price}
      size={size}
    />
  )
}
